import React, { useEffect, useState } from "react";
import ChatBox from "../components/ChatBox";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { useParams } from "react-router-dom";
import LeftSlider from "../../Inc/LeftSlider";
import AllTreatmentSelect from "./AllTreatmentSelect";
import UploadReports from "./UploadReports";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import "./patientDetail.css";
import PatinetReportList from "./PatinetReportList";
import MessagePopup from "./MessagePopup";
import { decryptId, encryptId } from "../../utils/cryptoUtils";
import SelectHospital from "./SelectHospital";
import ReportTemplateSend from "./ReportTemplateSend";
import UploadOpinion from "./UploadOpinion";
import WriteTreatment from "./WriteTreatment";
import { useNavigate } from "react-router-dom";
import LeadType from "../../common/LeadType";
import CloseLead from "./CloseLead";
import WhatsAppTemplateList from "./WhatsAppTemplateList";
import { Link } from "react-router-dom";
import VisaUploadList from "./VisaUploadList";
import { writeFile, utils } from "xlsx";
import VisaInvitationEmailList from "./VisaInvitationEmailList";
import { useMedflickApi } from "../../context/medflickContext";
import VisaDateUpdate from "./VisaDateUpdate";
import VisaSubmisiionDate from "./VisaSubmisiionDate";

const PatientDetail = () => {
  const { did } = useParams();
  const navigate = useNavigate();
  const id = decryptId(did);
  const { getHospitalNameById, getAllHospitals, hospitals } = useMedflickApi();
  const {
    leadDetail,
    getLeadbyId,
    patienLeadDetail,
    userLeadDetail,
    patinetReport,
    patientSpeciality,
    patientJourney,
    getConversion,
    patientConversation,
    getPatientPassport,
    patientPassport,
    getPatientOpinion,
    patientOpinion,
    getopinionSend,
    allOpinionSend,
    getUploadOpinion,
    getOpinionUpload,
    allLoading,
    getVisaInvitationEmail,
    visaInvitationEmail,
    getVisaUploadFile,
    visaUploadFile,
    getAllSendTicket,
    getSendTicket,
    getVisaSubmisiionDate,
    visaSubmissionDate,
  } = useGetLeadApi();

  const [showMessage, setShowMessage] = useState(false);
  const [writeTreatment, setWriteTreatment] = useState(false);
  const patinetId = patienLeadDetail?.id;
  const userId = userLeadDetail[0]?.id;
  const [conversationStatus, setConversationStatus] = useState("");
  const patientJourneyId = patientJourney?.id;

  const toggleMessage = (message) => {
    setShowMessage(!showMessage);
    setConversationStatus(message);
  };

  const openTreatment = () => {
    setWriteTreatment(!writeTreatment);
  };

  useEffect(() => {
    getAllHospitals();
    getLeadbyId(id);
    getConversion(patinetId, userId, patientJourneyId);
    getPatientPassport(patinetId);
    getPatientOpinion(patinetId);
    getopinionSend(patinetId, userId);
    getUploadOpinion(patinetId);
    getVisaInvitationEmail(patinetId, userId);
    getVisaUploadFile(patinetId, userId);
    getAllSendTicket(patinetId, userId);
    getVisaSubmisiionDate(patinetId, userId);
  }, [id, patientJourneyId, patinetId, userId]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${day}${getDaySuffix(
      day
    )} ${month} ${year} <span></span> ${formattedHours}:${formattedMinutes} ${period}`;
  };

  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const downloadReports = async (patientReport) => {
    if (!patientReport || patientReport.length === 0) {
      alert("No reports to download");
      return;
    }
    const zip = new JSZip();
    const folder = zip.folder("patientReports");

    const promises = patientReport.map(async (report) => {
      const response = await axios.get(report.reports, {
        responseType: "blob",
      });
      const filename = report.reports.split("/").pop();
      folder.file(filename, response.data);
    });

    await Promise.all(promises);
    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "patientReports.zip");
  };

  const handlepassport = () => {
    localStorage.setItem("patientId", patinetId);
    localStorage.setItem("userrrId", userId);
    navigate("/upload-passport");
  };

  const getBackgroundColor = (lead_type) => {
    switch (lead_type) {
      case "Warm":
        return "#c6c61c";
      case "Hot":
        return "green";
      case "Cold":
        return "orange";
      case "Dead":
        return "red";
      default:
        return "#fff";
    }
  };

  const handleClick = (e) => {
    if (!patientPassport || patientPassport.length === 0) {
      e.preventDefault(); // Prevent the Link from working
      alert("Please upload the patient passport first!");
    }
  };

  const handleDownload = () => {
    const data = visaInvitationEmail.map((item) => ({
      "Patient Name": leadDetail?.patient_name,
      "Patient Passport":
        item.patient_passport !== "null"
          ? item.patient_passport
          : "No Passport",
      "Attendant Name 1": item.attendant_name1,
      "Attendant Passport 1":
        item.attendant_passport1 !== "null"
          ? item.attendant_passport1
          : "No Passport",
      "Attendant Name 2":
        item.attendant_name2 !== "null" ? item.attendant_name2 : "No Attendant",
      "Attendant Passport 2":
        item.attendant_passport1 !== "null"
          ? item.attendant_passport1
          : "No Passport",
      "Attendant Name 3":
        item.attendant_name3 !== "null" ? item.attendant_name3 : "No Attendant",
      "Attendant Passport 3":
        item.attendant_passport3 !== "null"
          ? item.attendant_passport3
          : "No Passport",
      "Attendant Name 4":
        item.attendant_name4 !== null ? item.attendant_name4 : "No Attendant",
      "Attendant Passport 4":
        item.attendant_passport4 !== "null"
          ? item.attendant_passport4
          : "No Passport",
      Embassy: item.embassy,
      Hospital:
        item.hospital_id === "4" ? "IBS Hospital" : "Eternal Hospital Jaipur",
      "Appointment Date": new Date(item.appointment_date).toLocaleString(),
    }));

    // Create a new workbook and worksheet
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Visa Invitations");

    // Generate and download the Excel file
    writeFile(workbook, "Visa_Invitation_List.xlsx");
  };

  return (
    <>
      {allLoading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <section id="medflick-mod">
        <div className="head-top">
          <div className="head-top-left">
            {" "}
            <img src="/images/2024/01/logo.png" />{" "}
          </div>
          <div className="head-top-right">
            <a href="#">
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </a>
          </div>
        </div>

        <div className="medflick-mod">
          <LeftSlider />

          <div className="medflick-mod-right">
            <div className="scrollbar-1">
              <div
                className="patient-dashboard-doc"
                style={{
                  background: getBackgroundColor(leadDetail?.lead_type),
                }}
              >
                <div className="patient-dashboard-box">
                  <div className="patient-img-box">
                    {/* <div className="css-hra0ve"></div> */}
                    <img src="/images/userO.png" />
                  </div>
                  <div className="patient-doc-box">
                    <h2
                      style={{
                        textAlign: "start",
                        color: leadDetail?.lead_type ? "#fff" : null,
                      }}
                    >
                      {leadDetail?.patient_name}
                    </h2>
                    <div className="patient-doc-data">
                      <ul>
                        {/* <li>
                          <img src="/images/2024/01/03/1.png" /> Female
                        </li>
                        <li>
                          <img src="/images/2024/01/03/2.png" /> India
                        </li> */}
                        <li
                          style={{
                            color: leadDetail?.lead_type ? "#fff" : null,
                          }}
                        >
                          <img src="/images/2024/01/03/3.png" />{" "}
                          {patientSpeciality?.name}
                        </li>
                        <li
                          style={{
                            color: leadDetail?.lead_type ? "#fff" : null,
                          }}
                        >
                          <img src="/images/2024/01/03/4.png" />{" "}
                          {patienLeadDetail?.phone}
                        </li>
                        <li
                          style={{
                            color: leadDetail?.lead_type ? "#fff" : null,
                          }}
                        >
                          <img src="/images/2024/01/03/4.png" />{" "}
                          {patienLeadDetail?.email}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="patient-dashboard-query">
                  <h3
                    style={{
                      color: leadDetail?.lead_type ? "#fff" : null,
                    }}
                  >
                    Query
                  </h3>
                  <p
                    style={{
                      color: leadDetail?.lead_type ? "#fff" : null,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: formatDate(leadDetail?.created_at),
                    }}
                  />
                </div>
                <div className="patient-dashboard-reply">
                  <h3
                    style={{
                      color: leadDetail?.lead_type ? "#fff" : null,
                    }}
                  >
                    Source
                  </h3>
                  <p
                    style={{
                      color: leadDetail?.lead_type ? "#fff" : null,
                    }}
                  >
                    {leadDetail.platform ? leadDetail.platform : "Manually Add"}
                  </p>
                </div>
                {/* <div className="patient-dashboard-msg">
                  <a
                    href={
                      patienLeadDetail?.phone
                        ? `https://wa.me/${patienLeadDetail.phone}`
                        : "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Send Welcome Template
                  </a>
                </div> */}
                <div className="patient-dashboard-msg">
                  <LeadType
                    leadId={id}
                    leadtype={leadDetail?.lead_type}
                    getLeadbyId={getLeadbyId}
                  />
                  {/* <a
                    href={
                      patienLeadDetail?.phone
                        ? `https://wa.me/${patienLeadDetail.phone}`
                        : "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Message
                  </a> */}
                </div>
              </div>

              <div className="patient-dashboard-casebox">
                <div className="patient-dashboard-case-left">
                  <h2>
                    Case History{" "}
                    {/* <a href="#">
                      <img src="/images/2024/01/download.png" /> Download
                    </a> */}
                  </h2>

                  <div className="stage-text">
                    Stage <span>1/4</span>
                  </div>
                  <ul>
                    <li className={` ${patientSpeciality?.id ? "active" : ""}`}>
                      <div className="updates-img "></div>
                      <p>Medical Condition</p>
                      {patientSpeciality?.name && (
                        <>
                          <p>{patientSpeciality?.name}</p>
                          <div className="up-datalist">
                            {patientJourney?.speciality_date}
                          </div>
                        </>
                      )}
                    </li>
                    <li
                      className={` ${patinetReport.length > 0 ? "active" : ""}`}
                    >
                      <div className="updates-img"></div>

                      <p>Report Upload</p>
                      {patinetReport?.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(patinetReport[0]?.created_at),
                          }}
                        />
                      )}
                    </li>
                    <li
                      className={` ${
                        allOpinionSend.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img"></div>
                      <p>Opinion Send</p>
                      {allOpinionSend?.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(
                              allOpinionSend[allOpinionSend.length - 1]
                                ?.created_at
                            ),
                          }}
                        />
                      )}
                    </li>

                    <li
                      className={` ${
                        getOpinionUpload.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img"></div>
                      <p>Upload Opinion</p>
                      {getOpinionUpload?.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(
                              getOpinionUpload[getOpinionUpload.length - 1]
                                ?.created_at
                            ),
                          }}
                        />
                      )}
                    </li>
                    <li
                      className={` ${
                        patientPassport.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img"></div>
                      <p>Passport Upload </p>
                      {patientPassport?.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(
                              patientPassport[patientPassport.length - 1]
                                ?.created_at
                            ),
                          }}
                        />
                      )}
                    </li>
                    <li
                      className={` ${
                        visaInvitationEmail.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img"></div>
                      <p>Hospital Visa Letter </p>
                      {visaInvitationEmail?.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(
                              visaInvitationEmail[
                                visaInvitationEmail.length - 1
                              ]?.created_at
                            ),
                          }}
                        />
                      )}
                    </li>
                    <li
                      className={` ${
                        visaUploadFile.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img"></div>
                      <p>Visa Submission to Hospital</p>
                      {visaUploadFile?.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(
                              visaUploadFile[visaUploadFile.length - 1]
                                ?.created_at
                            ),
                          }}
                        />
                      )}
                    </li>
                    <li
                      className={` ${getSendTicket.length > 0 ? "active" : ""}`}
                    >
                      <div className="updates-img"></div>
                      <p>Ticket Send To Hospital</p>
                      {getSendTicket?.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(
                              getSendTicket[getSendTicket.length - 1]
                                ?.created_at
                            ),
                          }}
                        />
                      )}
                    </li>
                    <li
                      className={` ${
                        patientConversation?.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img"></div>
                      <p>Conversation</p>
                      <p>
                        {patientConversation.length > 0
                          ? patientConversation[0].health_description
                          : null}
                      </p>
                      {patientConversation.length > 0 && (
                        <div
                          className="up-datalist"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(
                              patientConversation[0]?.created_at
                            ),
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </div>
                <div className="patient-dashboard-case-right">
                  <div className="patient-dashboard-reports">
                    <h2>
                      Whatsapp Template (Promotion)
                      <Link
                        to={`/whatsapp-template/${did}`}
                        onClick={openTreatment}
                      >
                        Send Whatsapp Template
                      </Link>
                    </h2>

                    {/* <WhatsAppTemplateList
                      userId={userId}
                      patientId={patinetId}
                    /> */}
                  </div>

                  <div
                    className="patient-dashboard-reports"
                    style={{ marginTop: "20px" }}
                  >
                    <h2>
                      Department
                      <AllTreatmentSelect
                        patientId={patienLeadDetail?.id}
                        userId={userLeadDetail[0]?.id}
                        leadId={id}
                        treatmentId={patientSpeciality?.id}
                        getLeadbyId={getLeadbyId}
                        id={id}
                      />
                      <a href="#" onClick={openTreatment}>
                        Write Treatment
                      </a>
                      <ReportTemplateSend
                        leadPhoneNumber={patienLeadDetail?.phone}
                        leadName={patienLeadDetail?.name}
                      />
                      <a
                        onClick={() => downloadReports(patinetReport)}
                        style={{ color: "#fff" }}
                      >
                        <img src="/images/2024/01/download.png" /> Download
                      </a>
                    </h2>
                    <div className="dashboard-upload-reports">
                      <PatinetReportList
                        patinetReport={patinetReport}
                        formatDate={formatDate}
                        getLeadbyId={getLeadbyId}
                        leadId={id}
                      />
                      <UploadReports
                        patinetId={patienLeadDetail?.id}
                        userId={userLeadDetail[0]?.id}
                        getLeadbyId={getLeadbyId}
                        id={id}
                        patientSpeciality={patientSpeciality}
                      />
                    </div>
                  </div>
                  <SelectHospital
                    patinetId={patienLeadDetail?.id}
                    userId={userLeadDetail[0]?.id}
                    patinetReport={patinetReport}
                    patientSpeciality={patientSpeciality}
                    allOpinionSend={allOpinionSend}
                    getBackgroundColor={getBackgroundColor}
                    leadDetail={leadDetail}
                    encryptId={encryptId}
                    toggleMessage={toggleMessage}
                    patientConversation={patientConversation}
                    formatDate={formatDate}
                  />

                  <UploadOpinion
                    userId={userId}
                    patientId={patinetId}
                    opinions={getOpinionUpload}
                    patientName={leadDetail?.patient_name}
                    getBackgroundColor={getBackgroundColor}
                    leadDetail={leadDetail}
                    hospitals={hospitals}
                    getHospitalNameById={getHospitalNameById}
                  />

                  <div
                    className="patient-dashboard-reports"
                    style={{ marginTop: "20px" }}
                  >
                    <h2>
                      Passport{" "}
                      <a href="#" onClick={handlepassport}>
                        Upload Passport
                      </a>
                    </h2>
                    <div className="dashboard-upload-reportsbox">
                      {patientPassport && patientPassport.length > 0 ? (
                        patientPassport.map((e) => (
                          <div
                            className="dashboard-upload-reports-file"
                            key={e?.id}
                          >
                            <a
                              className="deleted-icon"
                              href="#"
                              // onClick={() => handleDelete(e.id)}
                            >
                              <img src="/images/del.png" />
                            </a>
                            <a
                              href={e?.upload_passport}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="upload-reports-text">
                                <h4>
                                  Passport{" "}
                                  <a
                                    href={e?.upload_passport}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="/images/2024/01/arrow-icon.png"
                                      alt="Arrow Icon"
                                    />
                                  </a>
                                </h4>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: formatDate(e?.created_at),
                                  }}
                                />
                              </div>
                            </a>
                          </div>
                        ))
                      ) : (
                        <p>No data found</p>
                      )}
                    </div>

                    {/* <div className="dashboard-upload-file-box">
                      <div className="file-input">
                        <img src="/images/2024/01/icon-pass.png" alt="" />
                        <input type="file" />
                        <span className="label" data-js-label="">
                          Complete opinion before applying for passport
                        </span>
                      </div>
                    </div> */}
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Hospital Visa Letter
                      <Link
                        to={`/send-visa/${encryptId(userId)}/${encryptId(
                          patinetId
                        )}/${encryptId(patientSpeciality?.id)}`}
                      >
                        Send Visa
                      </Link>
                      {visaInvitationEmail?.length > 0 ? (
                        <a
                          onClick={handleDownload}
                          style={{ cursor: "pointer", color: "#fff" }}
                        >
                          Download List
                        </a>
                      ) : null}
                    </h2>
                    <VisaInvitationEmailList
                      visaInvitationEmail={visaInvitationEmail}
                      getBackgroundColor={getBackgroundColor}
                      leadDetail={leadDetail}
                      hospitals={hospitals}
                      getHospitalNameById={getHospitalNameById}
                    />
                  </div>

                  <div className="patient-dashboard-reports">
                    <h2>Update Visa Submission and Recieve Date</h2>
                    {visaSubmissionDate.length > 0 ? (
                      <VisaSubmisiionDate visaData={visaSubmissionDate} />
                    ) : (
                      <VisaDateUpdate
                        userId={userId}
                        patientId={patinetId}
                        getVisaSubmisiionDate={getVisaSubmisiionDate}
                      />
                    )}
                  </div>
                  <div
                    className="patient-dashboard-reports"
                    style={{ marginTop: "20px" }}
                  >
                    <h2>
                      Visa Submission to Hospital
                      <Link
                        to={`/upload-visa/${encryptId(userId)}/${encryptId(
                          patinetId
                        )}/${encryptId(patientSpeciality?.id)}`}
                      >
                        Upload Visa
                      </Link>
                    </h2>
                    <VisaUploadList
                      visaData={visaUploadFile}
                      patinetName={leadDetail?.patient_name}
                      getBackgroundColor={getBackgroundColor}
                      leadDetail={leadDetail}
                      getHospitalNameById={getHospitalNameById}
                      hospitals={hospitals}
                    />
                  </div>

                  <div
                    className="patient-dashboard-reports"
                    style={{ marginTop: "20px" }}
                  >
                    <h2>
                      Ticket Submission to Hospital
                      <Link
                        to={`/send-ticket/${encryptId(userId)}/${encryptId(
                          patinetId
                        )}/${encryptId(patientSpeciality?.id)}`}
                      >
                        Send Ticket
                      </Link>
                    </h2>
                    {getSendTicket.length > 0 ? (
                      <div className="table-container">
                        <table className="details-table">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                ID
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Patient Name
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Visa Upload
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Ticket Upload
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Passport Number
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Name 1
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Passport 1
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Name 2
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Passport 2
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Name 3
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Passport 3
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Name 4
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Passport 4
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Embassy
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Patient Passport
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {getSendTicket?.map((detail) => (
                              <tr key={detail.id}>
                                <td>{detail.id}</td>
                                <td>{leadDetail?.patient_name}</td>
                                <td>
                                  <a
                                    href={detail.visa_upload}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Patient Visa
                                  </a>
                                </td>
                                <td>
                                  <a
                                    href={detail.ticket_upload}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Patient Ticket
                                  </a>
                                </td>
                                <td>{detail.passport_number}</td>
                                <td>{detail.attendant_name1}</td>
                                <td>
                                  <a
                                    href={detail.attendant_passport1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Attendant Passport 1
                                  </a>
                                </td>
                                <td>{detail.attendant_name2}</td>
                                <td>
                                  <a
                                    href={detail.attendant_passport2}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Attendant Passport 2
                                  </a>
                                </td>
                                <td>{detail.attendant_name3}</td>
                                <td>
                                  <a
                                    href={detail.attendant_passport3}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Attendant Passport 3
                                  </a>
                                </td>
                                <td>{detail.attendant_name4}</td>
                                <td>
                                  <a
                                    href={detail.attendant_passport4}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Attendant Passport 4
                                  </a>
                                </td>
                                <td>{detail.embassy}</td>
                                <td>
                                  <a
                                    href={detail.patient_passport}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Patient Passport
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>No Ticket Found</p>
                    )}
                  </div>
                  <div className="patient-dashboard-opinion">
                    <h2>
                      Conversation{" "}
                      <a href="#" onClick={() => toggleMessage("Conversation")}>
                        Message
                      </a>
                    </h2>
                    {patientConversation?.map((e) => (
                      <div key={e?.id} className="conversation-item">
                        <p className="health-description">
                          {e?.health_description}
                        </p>
                        <p
                          className="conversation-date"
                          dangerouslySetInnerHTML={{
                            __html: formatDate(e?.created_at),
                          }}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      <CloseLead
                        leadId={id}
                        status={leadDetail?.status}
                        getLeadbyId={getLeadbyId}
                        // getBackgroundColor={getBackgroundColor}
                        // leadType={leadDetail?.lead_type}
                      />
                      <a href="#">Your Feedback</a>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MessagePopup
        toggleMessage={toggleMessage}
        showMessage={showMessage}
        patientId={patienLeadDetail?.id}
        userId={userLeadDetail[0]?.id}
        getConversion={getConversion}
        patientJourneyId={patientJourneyId}
        conversationStatus={conversationStatus}
      />
      <WriteTreatment
        toggleMessage={openTreatment}
        showMessage={writeTreatment}
        patientId={patienLeadDetail?.id}
        userId={userLeadDetail[0]?.id}
        leadId={id}
        treatmentId={patientSpeciality?.id}
        getLeadbyId={getLeadbyId}
        id={id}
      />
      <ChatBox />
    </>
  );
};

export default PatientDetail;
